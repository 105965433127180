import { HeadFC } from 'gatsby';
import React, { Suspense, lazy } from 'react';

import { Seo } from '../../components';
import ErrorBoundary from '../../components/ErrorBoundary';
import Loader from '../../components/loading';
import { NavProvider } from '../../context/nav';

const LegalInformation = lazy(
  () => import('../../components/sections/LegalInformation'),
);

const info = [
  {
    text: `APPS MAKERS SAS (marca comercial DRAXO) y que en adelante será mencionado como Apps Makers SAS, es la empresa encargada de gestionar este sitio web. En todo el sitio, los términos "nosotros", "nos" y "nuestro" se refieren en lo sucesivo a APPS MAKERS SAS. APPS MAKERS SAS ofrece esta página web, incluida toda la información, las herramientas y los servicios que se ponen en este sitio a disposición suya, el usuario, siempre y cuando acepte la totalidad de los términos, condiciones, políticas y avisos contemplados aquí.
    <br />
    <br />
    Al visitar nuestro sitio y/o contratar o adquirir algún servicio mediante DRAXO.CO, usted interactúa con nuestro "Servicio" y reconoce como vinculantes los siguientes términos y condiciones (denominados en lo sucesivo "Términos del servicio", "Términos"), incluidos aquellos términos y condiciones adicionales y las políticas que se mencionan aquí y/o disponibles por medio de hipervínculo. Estos Términos del servicio se aplican a todos los usuarios del sitio, incluyendo de manera enunciativa mas no limitativa los usuarios que son navegadores, proveedores, compradores, comerciantes y/o que aporten contenido.
    <br />
    <br />
    Lea estos Términos del servicio detenidamente antes de acceder o utilizar nuestra página web. Al acceder o utilizar cualquier parte del sitio, usted acepta estos Términos del servicio. Si no acepta la totalidad de los términos y condiciones de este acuerdo, no podrá acceder al sitio web ni utilizar ningún servicio. Si estos Términos del servicio se considerasen una oferta, la aceptación se limita expresamente a los presentes Términos del servicio.
    <br />
    <br />
    Las nuevas funciones o herramientas que se agreguen a la página web actual, también estarán sujetas a los Términos del servicio. Puede revisar la versión más reciente de los Términos del servicio en cualquier momento en esta página. Nos reservamos el derecho de actualizar, cambiar o reemplazar cualquier parte de los presentes Términos del servicio mediante la publicación de actualizaciones o cambios en nuestra página web. Es su responsabilidad revisar esta página periódicamente para ver los cambios. Su uso de la página web o el acceso a ella de forma continuada después de la publicación de cualquier cambio, constituye la aceptación de dichos cambios.`,
  },
  {
    title: 'SECCIÓN 1: TÉRMINOS DE LA PÁGINA WEB',
    text: `1.1 Al utilizar la página web de Apps Makers SAS, aceptas los términos y condiciones establecidos en este documento.
    <br />
    <br />
    1.2 El uso de la página web de Apps Makers SAS está limitado a personas mayores de edad.
    <br />
    <br />
    1.3 Apps Makers SAS se reserva el derecho de restringir o prohibir el acceso a la página web a cualquier persona por cualquier motivo en cualquier momento.`,
  },
  {
    title: 'SECCIÓN 2: CONDICIONES GENERALES',
    text: `2.1 La página web de Apps Makers SAS se proporciona "tal cual" y sin garantías de ningún tipo, ya sean expresas o implícitas.
    <br />
    <br />
    2.2 Apps Makers SAS no se hace responsable de los daños directos, indirectos, incidentales, especiales, consecuentes o punitivos que resulten del uso de la página web.
    <br />
    <br />
    2.3 Apps Makers SAS se reserva el derecho de modificar, suspender o interrumpir el acceso a la página web en cualquier momento y sin previo aviso.`,
  },
  {
    title: 'SECCIÓN 3: EXACTITUD, TOTALIDAD Y CRONOLOGÍA DE LA INFORMACIÓN',
    text: `3.1 Apps Makers SAS no garantiza la exactitud, integridad o puntualidad de la información disponible a través de la página web.
    <br />
    <br />
    3.2 Toda la información proporcionada a través de la página web de Apps Makers SAS se presenta "tal cual" y sin garantías de ningún tipo.
    `,
  },
  {
    title: ' SECCIÓN 4: MODIFICACIONES A LA APLICACIÓN Y PRECIOS',
    text: `4.1 Apps Makers SAS se reserva el derecho de modificar o actualizar la página web y los precios de los productos y servicios en cualquier momento y sin previo aviso.
    <br />
    <br />
    4.2 Apps Makers SAS no será responsable de ningún daño o perjuicio causado por la modificación o actualización de la página web o los precios.`,
  },
  {
    title: 'SECCIÓN 5: PRODUCTOS O SERVICIOS (si corresponde)',
    text: `5.1 Los productos o servicios ofrecidos a través de la página web de Apps Makers SAS están sujetos a disponibilidad.
    <br />
    <br />
    5.2 Apps Makers SAS se reserva el derecho de modificar o descontinuar cualquier producto o servicio ofrecido a través de la página web en cualquier momento y sin previo aviso.`,
  },
  {
    title:
      'SECCIÓN 6: EXACTITUD DE LA FACTURACIÓN Y DE LA INFORMACIÓN DE LA CUENTA',
    text: `6.1 Apps Makers SAS se esforzará por proporcionar información precisa y actualizada sobre la facturación y la información de la cuenta a través de la página web.
    <br />
    <br />
    6.2 El usuario es responsable de proporcionar información precisa y actualizada sobre su cuenta y de mantener la seguridad de su cuenta.`,
  },
  {
    title: 'SECCIÓN 7: HERRAMIENTAS OPCIONALES',
    text: `7.1 Apps Makers SAS puede ofrecer herramientas opcionales a través de la página web, pero no se hace responsable de ningún daño o perjuicio causado por el uso de dichas herramientas.
    <br />
    <br />
    7.2 El uso de cualquier herramienta opcional ofrecida por Apps Makers SAS a través de la página web es bajo su propio riesgo y discreción.`,
  },
  {
    title: 'SECCIÓN 8: ENLACES DE TERCEROS',
    text: `Es posible que en nuestra plataforma en línea podamos ofrecer enlaces a sitios web de terceros que no están afiliados con Apps Makers SAS. No somos responsables por examinar o evaluar el contenido o la exactitud de estos sitios web, y no garantizamos ni asumimos responsabilidad alguna por cualquier material o sitio web de terceros, ni por cualquier otro material, producto o servicio de terceros.
    <br />
    Le recomendamos leer los términos y condiciones y la política de privacidad de cualquier sitio web de terceros que visite.
    `,
  },
  {
    title:
      'SECCIÓN 9: COMENTARIOS DE LOS USUARIOS, OPINIONES Y OTRAS COMUNICACIONES',
    text: `Si, a solicitud nuestra, usted envía ciertas presentaciones específicas (por ejemplo, participación en concursos) o sin una solicitud de nuestra parte, usted envía ideas creativas, sugerencias, propuestas, planes u otros materiales, ya sea en línea, por correo electrónico, por correo postal o de otra manera (colectivamente, "comentarios"), usted acepta que podemos, en cualquier momento, sin restricción, editar, copiar, publicar, distribuir, traducir y utilizar en cualquier medio cualquier comentario que nos envíe. No tenemos ni tendremos obligación alguna (1) de mantener ningún comentario en confidencialidad; (2) de pagar compensación por cualquier comentario; o (3) de responder a cualquier comentario.
    <br />
    <br />
    Nos reservamos el derecho de eliminar cualquier comentario que consideremos inapropiado, ofensivo o que viole estos términos y condiciones.`,
  },
  {
    title: 'SECCIÓN 10: INFORMACIÓN PERSONAL',
    text: 'La información personal que nos proporcione a través de nuestra plataforma en línea se regirá por nuestra política de privacidad.',
  },
  {
    title: 'SECCIÓN 11: ERRORES, INEXACTITUDES Y OMISIONES',
    text: `Ocasionalmente, puede haber información en nuestra plataforma en línea que contenga errores tipográficos, inexactitudes u omisiones que puedan relacionarse con descripciones de productos, precios, promociones, ofertas, gastos de envío de productos, tiempos de tránsito y disponibilidad. Nos reservamos el derecho de corregir cualquier error, inexactitud u omisión, y de cambiar o actualizar información o cancelar pedidos si cualquier información en la plataforma en línea es inexacta en cualquier momento sin previo aviso (incluso después de que haya enviado su pedido).
    <br />
    No asumimos ninguna obligación de actualizar, corregir o aclarar la información en la plataforma en línea, excepto cuando lo exija la ley.`,
  },
  {
    title: 'SECCIÓN 12: USOS PROHIBIDOS',
    text: `Además de otras prohibiciones establecidas en los Términos del Servicio, queda prohibido utilizar el sitio o su contenido:
    <br />
    a) con fines ilícitos;
    <br />
    b) para solicitar a otros que realicen o participen en actos ilícitos;
    <br />
    c) para infringir cualquier regulación, norma, ley u ordenanza local, internacional, federal, provincial o estatal;
    <br />
    d) para violar o infringir los derechos de propiedad intelectual de terceros;
    <br />
    e) para acosar, abusar, insultar, dañar, difamar, calumniar, desprestigiar, intimidar o discriminar por razones de género, orientación sexual, religión, etnia, raza, edad, origen nacional o discapacidad;
    <br />
    f) para presentar información falsa o engañosa;
    <br />
    g) para cargar o transmitir virus o cualquier otro tipo de código malicioso que pueda ser utilizado de cualquier forma que afecte la funcionalidad o el funcionamiento del Servicio o de cualquier sitio web relacionado, otros sitios o Internet;
    <br />
    h) para recopilar o rastrear información personal de otros;
    <br />
    i) para enviar spam, phishing, pharm, pretext, spider, crawl, or scrape;
    <br />
    j) para cualquier propósito obsceno o inmoral;
    <br />
    k) para interferir con o eludir las medidas de seguridad del Servicio o de cualquier sitio web relacionado, otros sitios o Internet;
    <br />
    l) para realizar actividades fraudulentas o delictivas, incluyendo, sin limitación, el lavado de dinero, la financiación del terrorismo o la explotación de menores.
    <br />
    <br />
    Nos reservamos el derecho de cancelar el uso del Servicio o cualquier sitio web relacionado por violar cualquiera de los usos prohibidos.`,
  },
  {
    title:
      'SECCIÓN 13: DESCARGO DE RESPONSABILIDAD DE GARANTÍAS; LIMITACIÓN DE RESPONSABILIDAD',
    text: `En ningún caso Apps Makers S.A.S., sus directores, funcionarios, empleados, afiliados, agentes, contratistas, internos, proveedores, prestadores de servicios o licenciantes serán responsables por cualquier daño, pérdida, reclamo, o cualquier daño directo, indirecto, incidental, punitivo, especial o consecuente de cualquier tipo, incluyendo, sin limitación, pérdida de beneficios, pérdida de ingresos, pérdida de ahorros, pérdida de datos, costos de reemplazo o cualquier daño similar, ya sea basado en contrato, agravio (incluyendo negligencia), responsabilidad objetiva o de otra manera, como resultado del uso de cualquier servicio o producto obtenido a través del sitio web, o por cualquier otro reclamo relacionado de alguna manera con su uso del servicio o cualquier producto, incluyendo, pero no limitado a, cualquier error u omisión en cualquier contenido, o cualquier pérdida o daño de cualquier tipo incurrido como resultado del uso del servicio o cualquier contenido (o producto) publicado, transmitido o puesto a disposición a través del servicio, incluso si se ha informado a Apps Makers S.A.S. sobre la posibilidad de tal daño.
    <br />
    <br />
    Como algunos estados o jurisdicciones no permiten la exclusión o la limitación de responsabilidad por daños consecuentes o incidentales, en dichos estados o jurisdicciones, la responsabilidad se limita en la medida máxima permitida por la ley.`,
  },
  {
    title: 'SECCIÓN 14: INDEMNIZACIÓN',
    text: 'Usted acepta indemnizar, defender y mantener indemne Apps Makers S.A.S. y nuestras subsidiarias, afiliados, socios, funcionarios, directores, agentes, contratistas, licenciantes, prestadores de servicios, subcontratistas, proveedores, internos y empleados, de cualquier reclamo o demanda, incluyendo honorarios razonables de abogados, realizados por cualquier tercero debido a o derivado de su incumplimiento de estos Términos de Servicio o de los documentos que incorporan como referencia, o su violación de cualquier ley o los derechos de un tercero.',
  },
  {
    title: 'SECCIÓN 15: DIVISIBILIDAD',
    text: 'En el caso de que se determine que cualquier disposición de estos Términos de Servicio sea ilegal, nula o inejecutable, dicha disposición se considerará separable de estos Términos de Servicio y no afectará la validez y aplicabilidad de las disposiciones restantes.',
  },
  {
    title: 'SECCIÓN 16: RESCISIÓN',
    text: `Estos Términos y condiciones son efectivos a menos que se rescindan por usted o por nosotros. Usted puede rescindir estos Términos y condiciones en cualquier momento notificándonos que ya no desea utilizar nuestros Servicios, o cuando deje de utilizar nuestro sitio web.
    <br />
    <br />
    Si a nuestro exclusivo juicio, usted incumple, o se sospecha que ha incumplido, cualquier término o disposición de estos Términos y condiciones, también podemos rescindir este acuerdo en cualquier momento sin previo aviso, y usted seguirá siendo responsable de todas las cantidades adeudadas hasta e incluyendo la fecha de rescisión; y/o podemos negarle acceso a nuestros Servicios (o cualquier parte del mismo).
    `,
  },
  {
    title: 'SECCIÓN 17: ACUERDO COMPLETO',
    text: `Nuestra falla para ejercer o hacer valer cualquier derecho o disposición de estos Términos y condiciones no constituirá una renuncia a tal derecho o disposición.
    <br />
    <br />
    Estos Términos y condiciones y todas las políticas o reglas de operación publicadas por nosotros en este sitio o con respecto a nuestro servicio constituyen el acuerdo completo y el entendimiento entre usted y nosotros y rigen su uso del Servicio, reemplazando cualquier acuerdo, comunicaciones y propuestas anteriores o contemporáneas, ya sean verbales o escritas, entre usted y nosotros (incluyendo, pero no limitado a, cualquier versión anterior de los Términos y condiciones).
    <br />
    <br />
    Cualquier ambigüedad en la interpretación de estos Términos y condiciones no se interpretará en contra de la parte redactora.`,
  },
  {
    title: 'SECCIÓN 18: LEY APLICABLE',
    text: 'Estos Términos y condiciones y cualquier acuerdo separado en el que le proporcionemos Servicios se regirán e interpretarán de acuerdo con las leyes de Colombia.',
  },
  {
    title: 'SECCIÓN 19: CAMBIOS EN LOS TÉRMINOS DEL SERVICIO',
    text: 'Nos reservamos el derecho, a nuestra sola discreción, de actualizar, cambiar o reemplazar cualquier parte de estos Términos y condiciones mediante la publicación de actualizaciones y cambios en nuestro sitio web. Es su responsabilidad verificar nuestro sitio web periódicamente para los cambios. Su uso continuo o acceso a nuestro sitio web o al Servicio después de la publicación de cualquier cambio en estos Términos y condiciones implica la aceptación de dichos cambios.',
  },
  {
    title: 'SECTION 20: INFORMACIÓN DE CONTACTO ',
    text: 'Cualquier pregunta acerca de los Términos y condiciones debe ser enviada a través de nuestro correo electrónico de contacto: info@draxo.co',
  },
];

export default function TermsConditions() {
  const isSSR = typeof window === 'undefined';
  if (isSSR) return null;
  return (
    <NavProvider>
      <ErrorBoundary>
        <Suspense fallback={Loader()}>
          <LegalInformation
            info={info}
            topTitle="Terms & Conditions"
            lastUpdate="01/10/2022"
          />
        </Suspense>
      </ErrorBoundary>
    </NavProvider>
  );
}

export const Head: HeadFC = () => <Seo />;
